<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>供应链管理</template>
      <template v-slot:secondMenu>分类列表</template>
    </breadcrumb-nav>
    <el-card shadow="never">
      <el-form :inline="true" :model="listQuery" ref="listQuery">
        <el-form-item label="名称" prop="name">
          <el-input  v-model="listQuery.name" placeholder="请输入分类名称"></el-input>
        </el-form-item>
        <el-form-item label="供应商">
          <el-select
            placeholder="请选择供应商"
            v-model="listQuery.supplierId"
            @change="supplierChange"
          >
            <el-option
              :label="item.desc"
              :value="item.supplierId"
              v-for="item in supplierList"
              :key="item.supplierId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否有选品" prop="status">
          <el-select v-model="listQuery.status" style="width:120px">
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="listCategory">查询</el-button>
          <el-button @click="resetForm()">重置</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px;">
      <tree-table
        :data="list"
        :columns="columns"
        :selection-type="false"
        :expand-type="false"
        show-index
        index-text="#"
        border
        class="tree-table">
        <template slot="order" slot-scope="scope">
          <el-tag size="mini" v-if="scope.row.level === 1">一级</el-tag>
          <el-tag type="success" size="mini" v-else-if="scope.row.level === 2">二级</el-tag>
          <el-tag type="warning" size="mini" v-else>三级</el-tag>
        </template>
        <template slot="status" slot-scope="scope">
          <i class="el-icon-success checkIcon" v-if="scope.row.status===1"></i>
          <i class="el-icon-error closeIcon" v-else></i>
        </template>
      </tree-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listQuery.page"
        :page-sizes="[5,10,20]"
        :page-size="listQuery.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../common/BreadcrumbNav'
import { getSupplierListRequest, getCategoryListRequest } from '../../network/supply'
export default {
  name: 'SupplyCategory',
  components: {
    BreadcrumbNav
  },
  filters: {
    statusTypeLabel (value) {
      switch (value) {
        case 1:
          return '是'
        case 2:
          return '否'
      }
    }
  },
  data () {
    return {
      // 总共数据条数
      total: 0,
      // 列表展示的数据
      list: [],
      listQuery: {
        page: 1,
        size: 10,
        status: 0,
        name: '',
        supplierId: 0,
        children: 1,
        parentId: 0
      },
      supplier: {},
      supplierList: [],
      statusOptions: [
        {
          label: '全部',
          value: 0
        },
        {
          label: '是',
          value: 1
        },
        {
          label: '否',
          value: 2
        }
      ],
      columns: [
        {
          label: '分类名称',
          prop: 'name'
        },
        {
          label: '级数',
          type: 'template',
          prop: 'level',
          template: 'order'
        },
        {
          label: '是否选品',
          type: 'template',
          prop: 'status',
          template: 'status'
        }
      ]
    }
  },
  // 组件挂载完毕发请求
  mounted () {
    // 获取列表数据方法
    this.getSupplierList()
  },
  methods: {
    getSupplierList () {
      getSupplierListRequest().then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取供应商列表失败', 'error')
        }
        this.supplierList = result.data.list
        if (this.supplierList.length > 0) {
          this.listQuery.supplierId = this.supplierList[0].supplierId
          this.supplier = this.supplierList[0]
        }
        this.listCategory()
      })
    },
    resetForm () {
      this.$refs.listQuery.resetFields()
      if (this.supplierList.length > 0) {
        this.listQuery.supplierId = this.supplierList[0].supplierId
      }
    },
    supplierChange (value) {
      console.log('supplier value:', value)
      this.listQuery.supplierId = value
    },
    listCategory () {
      getCategoryListRequest(this.listQuery).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取品牌列表失败', 'error')
        }
        this.list = result.data.list
        this.total = result.data.total
      })
    },
    // 监听size改变
    handleSizeChange (newSize) {
      this.listQuery.size = newSize
      this.listCategory()
    },
    // 监听page的改变
    handleCurrentChange (newPage) {
      this.listQuery.page = newPage
      this.listCategory()
    }
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
